import React from "react";
import SEO from "components/SEO/SEO";
import Features from "components/features/DashedBorderSixFeatures";
import Footer from "components/footers/MiniCenteredFooter.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import ThreeColSimpleWithImageAndDashedBorder from "components/blogs/ThreeColSimpleWithImageAndDashedBorder";

import tw from "twin.macro";

import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import backgroundImage from "../../images/spartan/wordpress-265132_1280.jpg";
import logo from "../../images/spartan/spartan_app_solutions_logo3_head.png";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const BlogPage = () => {
  return (
    <>
      <SEO
        title="Insights and Trends - Spartan App Solutions Blog | Mobile and Desktop App Development"
        description="Stay updated with the latest trends in mobile and desktop app development, technology solutions, and digital transformation. Explore expert insights from Spartan App Solutions."
        keywords="app development blog, mobile app trends, desktop application insights, technology solutions blog, digital transformation, cloud migration tips, business efficiency strategies, app development tutorials, Spartan App Solutions blog"
      />

      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText="Blogs"
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <ThreeColSimpleWithImageAndDashedBorder />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default BlogPage;
