import React from "react";
import { NavLinks, NavLink, PrimaryLink } from "../headers/light.js";

export default ({hireFlag}) => {
  return (
    <>
      <NavLinks key={1}>
        <NavLink href="/">Home</NavLink>
        <NavLink href="/Services">Services</NavLink>
        <NavLink href="/AboutUs">About Us</NavLink>
        <NavLink href="/Blog">Blogs</NavLink>
        <NavLink href="/ContactUs">Contact Us</NavLink>
      </NavLinks>
      {hireFlag && 
      <NavLinks key={2}>
        <PrimaryLink href="/ContactUs">Hire Us</PrimaryLink>
      </NavLinks>}
    </>
  );
};
