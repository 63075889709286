import React from 'react';

import FeaturesThreeCol from 'components/features/ThreeColWithSideImage.js';
import Features from 'components/features/VerticalWithAlternateImageAndText.js';
import Footer from 'components/footers/MiniCenteredFooter';
import Testimonial from 'components/testimonials/SimplePrimaryBackground.js'; 
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import TrustedBySection from 'components/testimonials/TrustedBySection.js';

import tw from 'twin.macro';
import SEO from 'components/SEO/SEO';

import BackgroundAsImageWithCenteredContent from '../components/hero/BackgroundAsImageWithCenteredContent';
import logo from '../images/spartan/spartan_app_solutions_logo3_head.png';
import backgroundImage from '../images/spartan/technology-6701504_1920.jpg';

const HighlightedText = tw.span`text-orange-spartan1`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <>
      <SEO
        title="Home - Spartan App Solutions – Custom-Crafted Mobile and Desktop Applications for Your Unique Needs"
        description="Transform your ideas into powerful mobile applications with Spartan App Solutions. Expert app development for iOS, Android, and custom software needs. Let's build something great together."
        keywords="spartan app, spartan solutions, app solutions, app it solutions, microsoft solutions spartan, cloud solutions spartan,in app solutions, mobile applications, desktop applications, technology solutions, business efficiency, digital transformation, cloud migration services, professional consulting"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          logo={logo}
          HireUs={true}
          headingText="We are consultants <br/> who provide tailored solutions  <br/> to drive your success"
          backgroundImage={backgroundImage}
        />
        
        <FeaturesThreeCol
          subheading={<Subheading>Our Features</Subheading>}
          heading={
            <>
              We have Amazing <HighlightedText>Support.</HighlightedText>
            </>
          }
        />
        <TrustedBySection/>
        <Features /> 
        <Testimonial />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
