import React from "react";
import tw from "twin.macro";

const Heading = tw.h1`text-3xl font-bold text-center text-blue-700 my-8`;
const Link = tw.a`text-lg text-blue-500 hover:text-blue-700 underline transition duration-300`;

export default () => {
  return (
    <>
      <Heading>Related Interesting Articles</Heading>
      <div className="flex flex-col items-center space-y-4">
        <Link href="/Blog/http-handling-call-error">
          HTTP Handling Call Error
        </Link><br/>
        <Link href="/Blog/mock-api">Mock API</Link>
      </div>
    </>
  );
};
