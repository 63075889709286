import React from "react";

import { SectionHeading } from "components/misc/Headings.js";
import styled from "styled-components";
import tw from "twin.macro";

import AnalyticsIconImage from "../../images/spartan/analytics-orange-illustration.svg";
import CloudIconImage from "../../images/spartan/cloud-orange-illustration.svg";
import ConsultIconImage from "../../images/spartan/consult-orange-illustration.svg";
import DevOpsIconImage from "../../images/spartan/devops-orange-illustration.svg";
import OpenAiIconImage from "../../images/spartan/openai-orange-illustration.svg";
import SoftwareIconImage from "../../images/spartan/software-orange-illustration.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "../../images/default/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-black rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-orange-spartan1 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-3 pt-3 font-semibold text-left text-secondary-100 text-sm leading-loose`}
  }
`;
const Link = tw.a`inline-block mt-4 text-sm text-orange-spartan1 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-orange-spartan1`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const CardDescription = ({ description }) => (
  <div
    className="description"
    dangerouslySetInnerHTML={{ __html: description }}
  ></div>
);

const ListItem = styled.li`
  ${tw`pt-3`}
  strong {
    ${tw`mt-2 font-bold text-sm leading-none text-orange-spartan1`}
  }
`;

const CardDescriptionList = ({ items }) => (
  <ul className="description">
    {items.map((item, index) => (
      <ListItem key={index}>
        <strong>{item.title}</strong> <br /> {item.content}
      </ListItem>
    ))}
  </ul>
);

export default () => {
  const cards = [
    {
      imageSrc: SoftwareIconImage,
      title: "Explore our Software Development Services",
      description: [
        {
          title: "Web Development",
          content:
            "Designing and maintaining dynamic websites and web applications that deliver an exceptional user experience and drive business growth.",
        },
        {
          title: "Mobile App Development",
          content:
            "Creating innovative, user-centric mobile applications for iOS and Android that ensure seamless integration and enhance engagement.",
        },
        {
          title: "Enterprise Software",
          content:
            "Developing robust, scalable, and secure software solutions tailored to meet the complex needs of large enterprises",
        },
      ],
      url: "/Software",
    },
    {
      imageSrc: CloudIconImage,
      title: "Discover our Cloud Services",
      description: [
        {
          title: "Dynamics 365 Integration",
          content:
            "Seamlessly integrate Dynamics 365 to create a unified, powerful platform that streamlines your business processes and enhances productivity.",
        },
        {
          title: "Expert Guidance",
          content:
            "Providing end-to-end cloud support, from migration to ongoing management, ensuring a smooth and efficient transition to the cloud",
        },
        {
          title: "Optimize Operations",
          content:
            "Delivering tailored cloud solutions designed to maximize efficiency, reduce costs, and drive operational excellence.",
        },
      ],
      url: "/Cloud",
    },
    {
      imageSrc: ConsultIconImage,
      title: "Learn More About Our Consulting Services",
      description: [
        {
          title: "Strategic Planning",
          content:
            "Guiding your business with expert strategic planning to unlock its full potential and achieve sustainable growth.",
        },
        {
          title: "Comprehensive Services",
          content:
            "Offering a wide range of consulting services, from process optimization to growth strategies, to help you stay ahead of the competition.",
        },
        {
          title: "Customized Solutions",
          content:
            "Providing personalized solutions that drive growth, reduce operational costs, and enhance overall efficiency.",
        },
      ],
      url: "/Consult",
    },
    {
      imageSrc: DevOpsIconImage,
      title: "Optimize Your Development with DevOps",
      description: [
        {
          title: "Streamline Development Lifecycle",
          content:
            "Enhancing efficiency and product quality through continuous integration and continuous delivery (CI/CD), ensuring faster time-to-market.",
        },
        {
          title: "Tailored Solutions",
          content:
            "Implementing customized DevOps practices that align with your specific requirements, leveraging cutting-edge tools and methodologies.",
        },
        {
          title: "Automated Processes",
          content:
            "Accelerating deployments and improving collaboration by automating key processes, boosting productivity across your teams.",
        },
      ],
      url: "/Devops",
    },

    {
      imageSrc: AnalyticsIconImage,
      title: "Transform Your Business with Analytics",
      description: [
        {
          title: "Actionable Insights",
          content:
            "Delivering powerful, data-driven insights that empower you to make informed, strategic decisions with confidence.",
        },
        {
          title: "Advanced Tools",
          content:
            "Utilizing state-of-the-art analytics techniques and tools to effectively analyze and visualize your data for maximum impact",
        },
        {
          title: "Unlock Growth Potential",
          content:
            "Identifying key trends and patterns to drive innovation, foster growth, and unlock new opportunities.",
        },
      ],
      url: "/Analytics",
    },
    {
      imageSrc: OpenAiIconImage,
      title: "Explore the Future with AI Solutions",
      description: [
        {
          title: "Empower Your Organization",
          content:
            "Building customized AI models and integrating advanced APIs, like ChatGPT, to meet your unique business needs and challenges.",
        },
        {
          title: "Digital Transformation",
          content:
            "Facilitating your organization’s adaptation to the digital era, ensuring you thrive in a rapidly changing landscape.",
        },
        {
          title: "Lead with AI-Driven Success",
          content:
            "Propelling your organization to new heights with cutting-edge AI solutions that drive success in the digital age.",
        },
      ],
      url: "/Openai",
    },
  ];
  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional Services</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                {Array.isArray(card.description) ? (
                  <CardDescriptionList items={card.description} />
                ) : (
                  <CardDescription description={card.description} />
                )}
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
