import React from 'react';
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from 'components/misc/Headings.js';
import { SectionDescription } from 'components/misc/Typography.js';
import CustomizeIconImage from 'images/spartan/customize-orange-icon.svg';
import FastIconImage from 'images/spartan/fast-orange-icon.svg';
import ReliableIconImage from 'images/spartan/reliable-orange-icon.svg';
import defaultCardImage from 'images/spartan/shield-orange-icon.svg';
import ShieldIconImage from 'images/spartan/shield-orange-icon.svg';
import SimpleIconImage from 'images/spartan/simple-orange-icon.svg';
import SupportIconImage from 'images/spartan/support-orange-icon.svg';
import {
  ReactComponent as SvgDecoratorBlob3,
} from 'images/default/svg-decorator-blob-3.svg';
import styled from 'styled-components';
import tw from 'twin.macro';

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "",
  subheading = "",
  description = "Whether you're looking to develop a new mobile app, modernize your web platform, or migrate your operations to the cloud, we have the expertise to help you achieve your goals."
}) => {
  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "Rest easy with our industry-leading security solutions. We prioritize the protection of your data with cutting-edge safeguards, ensuring your business stays safe from emerging threats. Trust us to keep your operations secure at all times."
    },
    {
      imageSrc: SupportIconImage,
      title: "Support",
      description: "Leverage our 24/7 expert support and our commitment to your success. We work with the industry's best to deliver exceptional service, ensuring you have the assistance you need, whenever you need it. Your satisfaction is our top priority."
    },
    {
      imageSrc: CustomizeIconImage,
      title: "Customizable",
      description: "Experience the flexibility of our customizable solutions, tailored to fit your specific needs. Whether you require small tweaks or extensive adjustments, we deliver bespoke services that align perfectly with your business objectives."
    },
    {
      imageSrc: ReliableIconImage,
      title: "Reliable",
      description: "Count on our proven reliability to keep your business running smoothly. Our services are engineered for maximum uptime, ensuring consistent and dependable performance, so you can focus on what matters most—growing your business."
    },
    {
      imageSrc: FastIconImage,
      title: "Fast",
      description: "Achieve rapid results with our efficient and timely services. We deliver on our promises quickly, without sacrificing quality, so your projects stay on track and on schedule. Experience the speed and precision of our expert team."
    },
    {
      imageSrc: SimpleIconImage,
      title: "Easy",
      description: "Enjoy the simplicity of our user-friendly solutions. Designed with you in mind, our services are intuitive and easy to use, reducing complexity and enhancing productivity. Seamlessly integrate our solutions into your workflow with minimal effort."
    }
  ]; 



  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
