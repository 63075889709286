import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import SEO from "components/SEO/SEO";
import BackgroundAsImageWithCenteredContent from "../../components/hero/BackgroundAsImageWithCenteredContent";
import logo from "./../../images/spartan/spartan_app_solutions_logo3_head.png";
import Footer from "components/footers/MiniCenteredFooter.js";
import backgroundImage from "../../images/spartan/blog-mock-api/mock-api-header.png";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "./../../components/misc/Headings.js";
import styled from "styled-components";
import { ReactComponent as CheckboxIcon } from "./../../images/default/checkbox-circle.svg"; 
import BlogFooter from "./blogFooter";

const postImages = [
  require("./../../images/spartan/blog-mock-api/mock_api_pic1.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic2.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic3.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic4.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic5.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic6.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic7.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic8.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic9.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic10.png"),
  require("./../../images/spartan/blog-mock-api/mock_api_pic11.png"),
];

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100 py-4`;

const FeatureHeading = tw.div`font-bold text-lg text-orange-spartan1`;

const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0 py-4`}
    .icon {
      ${tw`w-6 h-6 text-blue-spartan1 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-secondary-100 tracking-wide ml-3`}
    }
  }
`;

const ImageContainer = tw.div`flex justify-center my-8`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 lg:h-80 rounded rounded-b-none shadow-lg`,
  tw`bg-cover bg-center w-full max-w-md`, // Ensuring the image fills the container appropriately
]);

const VideoContainer = tw.div`flex justify-center my-8`;
const VideoIframe = styled.iframe`
  ${tw`w-full max-w-3xl h-64 sm:h-96 mt-4 rounded`} // Adjusting width and height for different screen sizes
`;

export default () => {
  return (
    <>
      <SEO
        title="Simplify API Development with MockAPI.io - A Guide for React.js Developers"
        description="Discover how to streamline your React.js development process using MockAPI.io. Learn to generate mock data, create resources, and enhance your projects without hard-coding data."
        keywords="MockAPI.io, API development, mock data, React.js, mock API, development tools, web development, mock data generation, MockAPI tutorial"
      />

      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          logo={logo}
          HireUs={false}
          headingText="Mock APIs"
          backgroundImage={backgroundImage}
        />
        <Container>
          <Content>
            <Subheading>Simplifying API Development</Subheading>
            <Heading>
              A Quick Guide to Generating Mock Data for Your React.js Projects
              with MockAPI.io
            </Heading>

            <Description>
              Welcome back, developers! Today, before diving into any React.js
              code, I want to introduce you to a fantastic tool that will
              simplify your development process: MockAPI.io. This platform
              allows you to generate mock data from a server without having to
              hard-code anything. Instead, you can create helper functions that
              call these endpoints, providing you with a seamless simulation
              environment for your projects.
              <FeatureHeading>Why MockAPI.io?</FeatureHeading>
              <ImageContainer>
                <Image imageSrc={postImages[0]} />
              </ImageContainer>
              <Description>
                MockAPI.io is perfect for developers who need a quick and easy
                way to simulate server responses without setting up a backend.
                By signing up for a free account, you can create one free
                project. For this example, we'll call our project "e-traveler."
              </Description>
              <ImageContainer>
                <Image imageSrc={postImages[1]} />
              </ImageContainer>
              <FeatureHeading>Getting Started with MockAPI.io</FeatureHeading>
              <Description>
                Once you've created your project, you’ll automatically be
                provided with an endpoint. However, to make this endpoint
                functional, you'll need to add a resource. Think of resources as
                the data structure or schema that your mock API will return.
              </Description>
              <Description>
                For this example, let's keep it simple by creating a
                "e-traveler" resource. This resource will contain two fields: an
                ID to uniquely identify each item and a "text" field to store
                the e-traveler item itself.
              </Description>
            </Description>
            <ImageContainer>
              <Image imageSrc={postImages[2]} />
            </ImageContainer>

            <PlanFeatures>
              <li className="feature">
                <CheckboxIcon className="icon" />
                <span className="text">
                  <strong>Creating Your First Resource</strong> In MockAPI.io,
                  add a new resource called "e-traveler."
                </span>
              </li>
              <ImageContainer>
                <Image imageSrc={postImages[3]} />
              </ImageContainer>
              <li className="feature">
                <CheckboxIcon className="icon" />
                <span className="text">
                  <strong>Define the Schema:</strong>Set up your schema by
                  including an ID and a "text" field, which will hold the
                  content of your e-traveler items.
                </span>
              </li>
              <ImageContainer>
                <Image imageSrc={postImages[4]} />
              </ImageContainer>
              <li className="feature">
                <CheckboxIcon className="icon" />
                <span className="text">
                  <strong>Generate Data:</strong>Specify how many records you
                  want. For example, you might request 16 records.
                </span>
              </li>
              <ImageContainer>
                <Image imageSrc={postImages[5]} />
              </ImageContainer>
            </PlanFeatures>
            <Description>
              After setting up your schema, MockAPI.io will generate random text
              entries for you. You can access this data by copying the provided
              endpoint and pasting it into your browser, followed by the
              resource name you created ("e-traveler" in this case). Instantly,
              you'll see the random text data displayed.
            </Description>
            <ImageContainer>
              <Image imageSrc={postImages[6]} />
            </ImageContainer>
            <ImageContainer>
              <Image imageSrc={postImages[7]} />
            </ImageContainer>
            <ImageContainer>
              <Image imageSrc={postImages[8]} />
            </ImageContainer>

            <FeatureHeading>Expanding Your Data</FeatureHeading>
            <Description>
              If you want more than just random text, MockAPI.io offers a
              feature called "Faker." This allows you to generate various types
              of data, such as zip codes, product names, and more. For instance,
              if you're building an e-commerce site and need a list of products,
              you can replace the "text" field with a "product" field. Simply
              reload the endpoint, and you'll get a new set of randomized
              product data.
            </Description>
            <ImageContainer>
              <Image imageSrc={postImages[9]} />
            </ImageContainer>
            <ImageContainer>
              <Image imageSrc={postImages[10]} />
            </ImageContainer>

            <FeatureHeading>Advanced Features</FeatureHeading>
            <Description>
              One of the coolest aspects of MockAPI.io is its ability to create
              nested, complex objects, which can be incredibly useful for more
              sophisticated applications. I highly recommend exploring these
              features to see how they can enhance your development workflow.
            

            </Description>

            <FeatureHeading>Final Thoughts</FeatureHeading>
            <Description>
              MockAPI.io is a powerful tool that can save you time and effort
              when developing React.js applications. By using this service, you
              can avoid hard-coding data, making your code cleaner and more
              adaptable. Keep the endpoint handy because we'll be using it in
              our upcoming React.js projects. Thanks for reading! Stay tuned for
              more tips and tricks to streamline your development process.
            </Description>

            <VideoContainer>
            <VideoIframe
              src={`https://www.youtube.com/embed/h9oixfUJ3zo`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></VideoIframe> 
              </VideoContainer>
              <BlogFooter/>
          </Content>
          
        
        </Container>
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
