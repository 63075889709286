import React from 'react';
import SEO from 'components/SEO/SEO';
import Features from 'components/features/DashedBorderSixFeatures';
import Footer from 'components/footers/MiniCenteredFooter.js';
import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import tw from 'twin.macro';

import BackgroundAsImageWithCenteredContent from '../components/hero/BackgroundAsImageWithCenteredContent';
import backgroundImage from '../images/spartan/business-5475661_1280.jpg';
import logo from '../images/spartan/spartan_app_solutions_logo3_head.png';

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const ServicesPage = () => {
  return (
    <>
      <SEO
        title="Services - Spartan App Solutions - Custom Mobile Application , Cross-Platform"
        description="Unlock the full potential of your business with custom software solutions from Spartan App Solutions. Tailored, scalable, and robust software development to meet your unique needs."
        keywords="spartan app, spartan solutions, app solutions, app it solutions, microsoft solutions spartan, cloud solutions spartan,in app solutions, mobile applications, desktop applications, technology solutions, business efficiency, digital transformation, cloud migration services, professional consulting"
      />
      <AnimationRevealPage>
        <BackgroundAsImageWithCenteredContent
          headingText="Services"
          logo={logo}
          backgroundImage={backgroundImage}
        />
        <Features />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};

export default ServicesPage;
